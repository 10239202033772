import PropTypes from 'prop-types';

import Question from './Question';

import { useFetch } from './storage';

import { FiRefreshCcw, FiLoader } from "react-icons/fi";

// A functional component to display a list of questions
export default function QuestionCycler({
	url,
	storageKey,
	players,
	onChange
}) {
	// Have a function tthat randomizes the items
	const randomizeWithExceptions = (array) => {
		// Make a copy of the array to avoid mutating the original
		let copy = array.slice();
		// Loop from the end of the array to the beginning
		for (let i = copy.length - 1; i > 0; i--) {
			// If the current item has the keepInOrder field set to true, find the previous item to keep in order
			let nextKeptInOrderIndex = i;
			if (copy[i].keepInOrder) {
				do {
					nextKeptInOrderIndex--;
				} while(nextKeptInOrderIndex > 0 && !copy[nextKeptInOrderIndex].keepInOrder);
				nextKeptInOrderIndex++;
			}
			let j;
			// Generate a random index between 0 and i (inclusive), or nextKeptInOrderIndex (exclusive) and i (inclusive) if it is an item to keep in order
			if(nextKeptInOrderIndex !== i) {
				j = Math.floor(Math.random() * (i - nextKeptInOrderIndex + 1)) + nextKeptInOrderIndex;
				// If item is to be kept in order, bail
				if(copy[j].keepInOrder) {
					continue;
				}
			} else {
				j = Math.floor(Math.random() * (i + 1));
				// If original item is to be kept in order, bail
				if(copy[i].keepInOrder) {
					continue;
				}
				// If target item is to be kept in order, bail
				if(copy[j].keepInOrder) {
					continue;
				}
			}
			// Swap the elements at i and j
			[copy[i], copy[j]] = [copy[j], copy[i]];
		}
		// Return the randomized array
		return copy;
	}

	// Use the custom hook to fetch the data from the URL
	const { data, loading, error, setData, resetData } = useFetch(url, storageKey, randomizeWithExceptions);

	// A function to delete a question from the data and localStorage
	const deleteQuestion = (id) => {
		// Filter out the question with the given id
		const newQuestions = data.filter((question) => question.id !== id);
		// Set the new questions to the state and localStorage
		setData(newQuestions);
		// Return new data
		return newQuestions;
	};

	let question = null;
	let backgroundColor = "bg-amber-100";
	let isFinished = false;
	if (data) {
		if (data.length === 0) {
			isFinished = true;
		} else {
			// Clone question
			question = Object.assign({}, data[0]);

			// If we do not have enough players, remove this question and go to the next
			if(question.nb_players > 0 && question.nb_players >= players.length) {
				deleteQuestion(question.id);
				question = null;
			} else {
				if(question.type === "action") {
					backgroundColor = "bg-amber-100";
				} else if(question.type === "defi") {
					backgroundColor = "bg-lime-300";
				}
	
				let turnPlayers = players.slice();
				turnPlayers.sort(() => 0.5 - Math.random());
	
				let currentPlayer = 0;
				while(question.text.indexOf('%s') > -1) {
					question.text = question.text.replace('%s', turnPlayers[currentPlayer]);
					currentPlayer++;
				}
				while(question.text.indexOf('$') > -1) {
					question.text = question.text.replace('$', Math.floor(Math.random() * (5 - 1 + 1) + 1));
				}
			}
		}
	}

	return (
		<div className={`w-full h-full p-4 flex flex-col justify-center content-center text-center transition-colors duration-100 ${backgroundColor}`}>
			{!loading && <div class="flex-none mb-4">
				<button class="p-2 rounded-full bg-slate-400/25" onClick={resetData}><FiRefreshCcw /></button>	
			</div>}
			{loading && <div class="text-center"><FiLoader class="animate-spin inline-block" /></div>}
			{error && <div class="flex-1 flex flex-col justify-center">
				<p class="border-2 border-rose-500 p-2">{error}</p>
			</div>}
			{!loading && !error && isFinished && <div class="flex-1 flex flex-col justify-center">
				<p>Bravo, vous avez atteint la fin ! Vous pouvez redémarrer une partie en cliquant sur le bouton en haut à droite.</p>
			</div>}
			{!loading && !error && question && (
				<Question text={question.text} onChange={() => { const newData = deleteQuestion(question.id); onChange && onChange(newData); }} />
			)}
		</div>
	);
};

QuestionCycler.propTypes = {
	/**
	 * URL where to fetch questions
	 */
	url: PropTypes.string.isRequired,
	/**
	 * Local storage key to use for cached local data
	 */
	storageKey: PropTypes.string,
	/**
	 * Player names array
	 */
	players: PropTypes.array.isRequired,
	/**
	 * Question ist change information trigger
	 */
	onChange: PropTypes.func
};

QuestionCycler.defaultProps = {
	storageKey: 'questions',
	onChange: undefined,
};
