import PropTypes from 'prop-types';

import { FiChevronRight } from "react-icons/fi";

export default function Question({
	text,
	onChange
}) {
	return (
		<div class="flex-1 flex flex-col">
			<span class="flex-1"></span>
			<p class="">{text}</p>
			<span class="flex-1"></span>
			<button onClick={onChange} class="flex-none mb-8 p-4 rounded-full bg-slate-400/25">
				<FiChevronRight class="inline-block" />
			</button>
		</div>
	)
}

Question.propTypes = {
	/**
	 * Question text to display
	 */
	text: PropTypes.string.isRequired,
	/**
	 * Question type, determining the color
	 */
	type: PropTypes.oneOf(['action']),
	/**
	 * Next question handler
	 */
	onChange: PropTypes.func.isRequired
};

Question.defaultProps = {
	type: 'action'
};
