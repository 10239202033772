import QuestionCycler from "./QuestionCycler";

function App() {
	const players = [
		'Louis-Felix',
		'Pierre',
		'Thomas',
		'Casquette',
		'Aymane',
		'Hana',
		'Hugo',
		'Enzo',
		'Yann',
		'Eliott',
		'Pierre-Louis',
		'Ellen',
		'Anais',
		'Cannelle',
		'Pierre-Jean',
		'Raphael',
		'Clara'
	];

	return (
		<QuestionCycler url="/questions.json" players = {players} />
	);
}

export default App;
