import { useState, useEffect } from 'react';

// A custom hook to fetch data from a URL and store it in localStorage
export const useFetch = (url, key, loadMutator = undefined) => {
	const [inMemoryData, setInMemoryData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	// A function to reset the localStorage and fetch data from the URL
	const setData = (data) => {
		// Set the data to the state and localStorage
		setInMemoryData(data);
		localStorage.setItem(key, JSON.stringify(data));
		setLoading(false);
	};

	// A function to fetch data from a URL and store it in localStorage
	const fetchData = (url) => {
		setLoading(true);
		setError(null);
		fetch(url)
			.then((response) => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error(`Something went wrong (${response.status}, ${response.statusText})`);
				}
			})
			.then((data) => {
				// Set the data to the state and localStorage
				setData(loadMutator ? loadMutator(data) : data);
				setLoading(false);
			})
			.catch((error) => {
				// Set the error to the state
				setError(error.message);
				setLoading(false);
			});
	};

	useEffect(() => {
		// Check if the data is already in localStorage
		const cachedData = localStorage.getItem(key);
		if (cachedData) {
			// Parse the data and set it to the state
			setData(JSON.parse(cachedData));
		} else {
			// Fetch the data from the URL
			fetchData(url);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// A function to reset the localStorage and fetch data from the URL
	const resetData = () => {
		// Clear the localStorage
		localStorage.clear();
		// Fetch the data from the URL
		fetchData(url);
	};

	return { data: inMemoryData, loading, error, setData, resetData };
};
